<template>
  <div class="z-50 w-full h-[90vh] flex justify-center mt-[16rem]">
    <span class="loader"></span>
  </div>
</template>

<script>
</script>

<style>
.loader {
  width: 40px;
  height: 40px;
  margin-top: 30px;
  display: inline-block;
  position: relative;
  background: #000;
  border-radius: 15% 15% 35% 35%;
}
.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 40px;
  top: 8px;
  border: 4px solid #000;
  width: 16px;
  height: 20px;
  border-radius: 0 4px 4px 0;
}
.loader::before {
  content: '';
  position: absolute;
  width: 1px;
  height: 10px;
  color: #000;
  top: -15px;
  left: 11px;
  box-sizing: border-box;
  animation: animloader 1s ease infinite;
}

@keyframes animloader {
    0% {
  box-shadow: 2px 0px rgba(0, 0, 0, 0), 12px 0px rgba(0, 0, 0, 0.3), 20px 0px rgba(0, 0, 0, 0);
}
    50% {
  box-shadow: 2px -5px rgba(0, 0, 0, 0.5), 12px -3px rgba(0, 0, 0, 0.5), 20px -2px rgba(0, 0, 0, 0.6);
}
    100% {
  box-shadow: 2px -8px rgba(0, 0, 0, 0), 12px -5px rgba(0, 0, 0, 0), 20px -5px rgba(0, 0, 0, 0);
}
  }
</style>
