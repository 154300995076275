import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import('../views/HomeView.vue'),
    redirect: '/home',
    children: [
      /* 客戶端前台 */
      {
        path: 'home',
        name: 'about',
        component: () => import('../views/client/AboutView.vue')
      },
      /* 大蒜一覽，List & /:id */
      {
        path: 'products',
        name: 'products',
        component: () => import('../views/client/ProductsView.vue')
      },
      {
        path: 'product/:id',
        name: 'product',
        component: () => import('../views/client/ProductView.vue'),
        props: (route) => {
          return {
            id: route.params.id
          }
        }
      },
      /* 大蒜實驗室，介紹大蒜料理 */
      {
        path: 'lab',
        name: 'lab',
        component: () => import('../views/client/LabView.vue')
      },
      /* 蒜友登入 */
      {
        path: 'login',
        name: 'login',
        component: () => import('../views/client/LoginView.vue')
      },
      {
        path: 'user',
        name: 'user',
        component: () => import('../views/client/DashboardView.vue')
      },
      {
        path: 'cart',
        name: 'cart',
        component: () => import('../views/client/CartView.vue')
      }
    ]
  },
  /* 管理員後台 */
  {
    path: '/admin',
    component: () => import('../views/AdminView.vue'),
    children: [
      {
        path: 'login',
        component: () => import('../views/admin/LoginView.vue')
      },
      {
        path: 'dashboard',
        component: () => import('../views/admin/DashboardView.vue')
      },
      {
        path: 'products',
        component: () => import('../views/admin/ProductsView.vue')
      },
      {
        path: 'orders',
        component: () => import('../views/admin/OrdersView.vue')
      },
      {
        path: 'coupon',
        component: () => import('../views/admin/CouponView.vue')
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('../views/HomeView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active'
})

export default router
