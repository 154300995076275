<template>
  <div class="bg-brandbg/20">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  components: {
  }
}
</script>
