import emitter from './emitter'

export default function (res, title = '更新') {
  if (res.data.success) {
    emitter.emit('push-notification', {
      style: 'green-500',
      title: `${title}成功`
    })
  } else {
    const notification = typeof res.data.message === 'string'
      ? [res.data.message]
      : res.data.message
    emitter.emit('push-notification', {
      style: 'red-500',
      title: `${title}失敗`,
      content: notification.join('、')
    })
  }
}
